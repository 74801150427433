import { env } from '$env/dynamic/public';
import { init } from '@jill64/sentry-sveltekit-cloudflare/client';
import * as Sentry from '@sentry/svelte';

const onErrorSentry = init(
  'https://8c231b181c39b4cd51b7e45f93a62ecf@o4507072891060225.ingest.de.sentry.io/4507311654502480',
  {
    sentryOptions: {
      tracesSampleRate: 1.0,

      integrations: [Sentry.replayIntegration()],
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,

      environment: env.PUBLIC_SENTRY_ENVIRONMENT
    },
    enableInDevMode: false
  }
);

export const handleError = onErrorSentry();
