import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [2,3,4];

export const dictionary = {
		"/(singed-in)/(verified)": [~6,[2,3]],
		"/(singed-in)/(verified)/(hub-staff)/analytics": [~8,[2,3]],
		"/(singed-in)/(verified)/buy-credit": [~19,[2,3]],
		"/(singed-in)/(verified)/(admin)/hub-payment/[userId]": [~7,[2,3,4]],
		"/(singed-in)/(verified)/(hub-staff)/hubs": [~9,[2,3]],
		"/(singed-in)/(verified)/(hub-staff)/hubs/[hubId]": [~10,[2,3]],
		"/(singed-in)/(verified)/(hub-staff)/hubs/[hubId]/batteries": [~11,[2,3]],
		"/(singed-in)/(verified)/(hub-staff)/hubs/[hubId]/batteries/[batteryId]": [~12,[2,3]],
		"/(singed-in)/(verified)/(hub-staff)/hubs/[hubId]/rentals": [~13,[2,3]],
		"/(singed-in)/(verified)/(hub-staff)/hubs/[hubId]/rentals/[rentalId]/issue": [~14,[2,3]],
		"/(singed-in)/(verified)/(hub-staff)/hubs/[hubId]/rentals/[rentalId]/return": [~15,[2,3]],
		"/(singed-out)/privacy-policy": [25,[5]],
		"/(singed-in)/profile": [~22,[2]],
		"/(singed-in)/(verified)/rentals": [~20,[2,3]],
		"/(singed-in)/(verified)/reserve-battery": [~21,[2,3]],
		"/(singed-out)/reset-password/send-code": [~27,[5]],
		"/(singed-out)/reset-password/verify-code/[phoneNumber]": [~28,[5]],
		"/(singed-out)/reset-password/[tokenId]": [~26,[5]],
		"/(singed-out)/sign-in": [~29,[5]],
		"/(singed-in)/sign-out": [~23,[2]],
		"/(singed-out)/sign-up": [~30,[5]],
		"/(singed-out)/terms-of-service": [31,[5]],
		"/(singed-in)/(verified)/(hub-staff)/trackers": [~16,[2,3]],
		"/(singed-in)/(verified)/(hub-staff)/trackers/[trackerId]": [~17,[2,3]],
		"/(singed-out)/user-payment/[paymentId]": [~32,[5]],
		"/(singed-in)/(verified)/(hub-staff)/users": [~18,[2,3]],
		"/(singed-in)/verify-account": [~24,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';